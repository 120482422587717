  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter&family=Lato:wght@300;700&family=Montserrat:wght@300;400;800&family=Poppins&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu&family=Varela+Round&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html{
        font-family: "Raleway",sans-serif;
        background-color:#1f2937;
        /* background-color:#000; */
    }
}